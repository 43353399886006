<template>
  <b-card no-body>
    <b-card-header
        class="email-detail-head"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <b-avatar
            class="avatar"
            size="50"
            variant="light-info"
            :src="getCodeIcon('user', message.creator)"
            :text="avatarText(getCodeLabel('user', message.creator))"
        >
        </b-avatar>
        <div class="mail-items">
          <h5 class="mb-0">
            {{ getCodeLabel('user', message.creator) }}
          </h5>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time text-muted">{{ toTime(message.create_time) }}</small>
      </div>
    </b-card-header>

    <b-card-body class="mail-message-wrapper pt-2">
      <!-- eslint-disable vue/no-v-html -->
      <div
          class="mail-message"
          v-html="message.content"
      />
      <!-- eslint-enable -->
    </b-card-body>

    <b-card-footer v-if="message.attachments && message.attachments.length">
      <div class="mail-attachments">
        <div class="d-flex align-items-center mb-1">
          <feather-icon
              icon="PaperclipIcon"
              size="16"
          />
          <h5 class="font-weight-bolder text-body mb-0 ml-50">
            附件
          </h5>
        </div>

        <div class="d-flex flex-column">
          <attachment-upload :key="att"
                             :theme="'files'"
                             :attachment_id="'attachments'"
                             :id="message.attachments"
                             :object_type="'note_book'"
                             :object_id="message.note_id"
                             :readonly="true"
          />
        </div>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BDropdown, BDropdownItem, BCard, BCardHeader, BCardBody, BCardFooter, BAvatar, BLink, BImg,
} from 'bootstrap-vue'
import {formatDate, toDate, getCodeLabel, toTime, getCodeIcon, avatarText, getCodeColor} from '@core/utils/filter';
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload';
import store from "@/store";
import quillConfig from "@/views/apps/news/quill-config";

export default {
  components: {
    BDropdown, BDropdownItem, BCard, BCardHeader, BCardBody, BCardFooter, BAvatar, BLink, BImg, AttachmentUpload
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      att: ''
    }
  },
  methods: {
    updateLoad() {
      this.att = this.message.attachments
    }
  },
  setup() {
    return {
      toDate,
      toTime,
      formatDate,
      getCodeLabel,
      getCodeIcon,
      avatarText,
      getCodeColor
    }
  },
  watch: {
    message: function () {
      this.updateLoad()
    }
  }
}
</script>

<style>

</style>
