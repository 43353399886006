<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
        class="body-content-overlay"
        :class="{'show': mqShallShowLeftSidebar}"
        @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
              icon="MenuIcon"
              size="21"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
                v-model="searchKey"
                placeholder=" 请 输 入 关 键 词 搜 索~~"
                @input="changeList"
            />
          </b-input-group>
        </div>
      </div>

      <!-- App Action Bar -->
      <div class="app-action">
        <div class="action-left">
          <b-form-checkbox
              :checked="selectAllNoteCheckbox"
              :indeterminate="isSelectAllNoteCheckboxIndeterminate"
              @change="selectAllCheckboxUpdate1"
          >
            选择全部
          </b-form-checkbox>
        </div>
        <div
            v-show="selectedNotes.length"
            class="align-items-center"
            :class="{'d-flex': selectedNotes.length}"
        >
          <feather-icon
              icon="TrashIcon"
              size="17"
              class="cursor-pointer ml-1"
              @click="deleteNotes"
          />

        </div>
      </div>

      <!-- Notes List -->
      <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="email-user-list scroll-area"
      >
        <ul class="email-media-list">
          <b-media
              v-for="note in notes"
              :key="note.note_id"
              tag="li"
              no-body
              :class="'mail-read'"
              @click="updateNoteViewData(note)"
          >

            <b-media-aside class="media-left mr-50">
              <b-avatar
                  class="avatar"
                  size="50"
                  variant="light-primary"
                  :src="getCodeIcon('user', note.creator)"
                  :text="avatarText(getCodeLabel('user', note.creator))"
              >
              </b-avatar>
              <div class="user-action">
                <b-form-checkbox
                    :checked="selectedNotes.includes(note.note_id)"
                    @change="toggleSelectedNote(note.note_id)"
                    @click.native.stop
                />
              </div>
            </b-media-aside>

            <b-media-body>
              <div class="mail-details">
                <div class="mail-items">
                  <h5 class="mb-25">
                    {{ getCodeLabel('user', note.creator) }}
                  </h5>
                  <div>
                    <b-badge pill :variant="`light-${getCodeColor('note_type', note.note_type)}`"
                             style="margin-left: 5px;">
                      {{ getCodeLabel('note_type', note.note_type) }}
                    </b-badge>
                    <b-badge pill :variant="`light-${getCodeColor('note_status', note.status)}`"
                             style="margin-left: 5px;">
                      <feather-icon
                          :icon="`${getCodeIcon('note_status', note.status)}`"
                          class="mr-25"
                      />
                      {{ getCodeLabel('note_status', note.status) }}
                    </b-badge>
                    <b-badge pill :variant="`light-${getCodeColor('note_level', note.note_level)}`"
                             style="margin-left: 5px;">
                      {{ getCodeLabel('note_level', note.note_level) }}
                    </b-badge>
                  </div>
                  <span class="text-truncate">{{ note.note_title }}</span>
                </div>
                <div class="mail-meta-item">
                  <feather-icon
                      v-if="note.attachments !==''"
                      icon="PaperclipIcon"
                  />
                </div>
              </div>

              <div class="mail-message">
                <p class="text-truncate mb-0">
                  {{ filterTags(note.content) }}
                </p>
              </div>
              <div align="end">
                {{ toTime(note.create_time) }}
              </div>
            </b-media-body>
          </b-media>
        </ul>
        <div
            class="no-results"
            :class="{'show': !notes.length}"
        >
          <h5>暂时没有笔记哦 ^_^</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Email View/Detail -->
    <note-view
        :class="{'show': showNoteDetails}"
        :note-view-data="noteViewData"
        :opended-note-meta="opendedNoteMeta"
        @close-note-view="showNoteDetails = false"
        @move-email-to-folder="moveOpenEmailToFolder"
        @toggle-note-starred="toggleStarred(noteViewData)"
        @update-email-label="updateOpenEmailLabel"
        @mark-email-unread="markOpenEmailAsUnread"
        @change-opened-email="changeOpenedEmail"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <note-left-sidebar
          :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
          :emails-meta="emailsMeta"
          :class="{'show': mqShallShowLeftSidebar}"
          @close-left-sidebar="mqShallShowLeftSidebar = false"
          :search-list="searchList"
      />
    </portal>

    <!-- Compose Email Modal -->
    <note-compose v-model="shallShowEmailComposeModal" :edit-id="0"/>
  </div>
</template>

<script>
import store from '@/store'
import {computed, onUnmounted, ref, watch,} from '@vue/composition-api'
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BMedia,
  BMediaAside,
  BMediaBody,
  BBadge,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  filterTags,
  toDate,
  toTime,
  formatDateToMonthShort,
  getCodeLabel,
  getCodeColor,
  getCodeIcon,
  avatarText
} from '@core/utils/filter'
import {useRouter} from '@core/utils/utils'
import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
import emailStoreModule from './emailStoreModule'
import useEmail from './useNote'
import NoteLeftSidebar from "./NoteLeftSidebar.vue";
import NoteView from "./NoteView.vue";
import NoteCompose from "./NoteCompose.vue";
import notebookStore from "@/views/apps/note/notebookStore";
import {useToast} from "vue-toastification/composition";
import notebookUseList from './notebookUseList'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BBadge,

    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    NoteLeftSidebar,
    NoteView,
    NoteCompose,
  },

  provide() {
    return {
      closeView: this.closeView,
      searchList: this.searchList,
      updateNoteViewData: this.updateNoteViewData,
    };
  },

  data() {
    return {
      // notes: [],
      start: ref(1),
      limit: ref(100),
      limitOptions: [10, 25, 50, 100],
      listTotals: ref(0),
      searchKey: '',
      orderBy: ref('note_id'),
      isSortDirDesc: ref(true),
      noteType: '',
      noteStatus: '',
      noteLevel: '',
    }
  },

  setup() {
    const toast = useToast()
    const EMAIL_APP_STORE_MODULE_NAME = 'app-email'

    // Register module
    if (!store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.registerModule(EMAIL_APP_STORE_MODULE_NAME, emailStoreModule)
    if (!store.hasModule('notebook')) store.registerModule('notebook', notebookStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.unregisterModule(EMAIL_APP_STORE_MODULE_NAME)
      if (store.hasModule('notebook')) store.unregisterModule('notebook')
    })

    const {route, router} = useRouter()
    const {resolveLabelColor} = useEmail()

    // Route Params
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      fetchEmails()
    })

    // Emails & EmailsMeta
    const emails = ref([])
    const emailsMeta = ref({})

    const notes = ref([])
    const notesMeta = ref({})

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Search Query
    const routeQuery = computed(() => route.value.query.q)
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })

    watch(searchQuery, () => fetchEmails())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({name: route.name, query: currentRouteQuery})
    }

    const fetchEmails = () => {
      store.dispatch('app-email/fetchEmails', {
        q: searchQuery.value,
        folder: router.currentRoute.params.folder || 'inbox',
        label: router.currentRoute.params.label,
      })
          .then(response => {
            emails.value = response.data.emails
            emailsMeta.value = response.data.emailsMeta
          })
    }

    fetchEmails()

    const changeList = function () {
      this.searchList()
    }

    const searchList = function (msg) {
      if (msg != undefined) {
        if (msg.status != undefined) {
          this.noteStatus = msg.status
        }
        if (msg.level != undefined) {
          this.noteLevel = msg.level
        }
      }
      store
          .dispatch('notebook/search', {
            searchKey: this.searchKey.replaceAll("'", ""),
            start: this.start.value,
            limit: this.limit.value,
            noteType: this.noteType,
            status: this.noteStatus,
            noteLevel: this.noteLevel,
            order_by: this.orderBy.value,
            order_desc: this.isSortDirDesc.value === true ? 'desc' : '',
          })
          .then(response => {
            const data = response.data.data
            notes.value = data.ext.list
          })
          .catch((e) => {
            toast({
              component: ToastificationContent,
              props: {
                title: '列表获取错误',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }

    const selectedEmails = ref([])
    const toggleSelectedMail = mailId => {
      const mailIndex = selectedEmails.value.indexOf(mailId)

      if (mailIndex === -1) selectedEmails.value.push(mailId)
      else selectedEmails.value.splice(mailIndex, 1)
    }
    const selectAllEmailCheckbox = computed(() => emails.value.length && (emails.value.length === selectedEmails.value.length))
    const isSelectAllEmailCheckboxIndeterminate = computed(() => Boolean(selectedEmails.value.length) && emails.value.length !== selectedEmails.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedEmails.value = val ? emails.value.map(mail => mail.id) : []
    }

    const selectedNotes = ref([])
    const toggleSelectedNote = function (noteId) {
      const noteIndex = selectedNotes.value.indexOf(noteId)

      if (noteIndex === -1) selectedNotes.value.push(noteId)
      else selectedNotes.value.splice(noteIndex, 1)
    }
    const selectAllNoteCheckbox = computed(() => notes.value.length && (notes.value.length === selectedNotes.value.length))
    const isSelectAllNoteCheckboxIndeterminate = computed(() => Boolean(selectedNotes.value.length) && notes.value.length !== selectedNotes.value.length)
    const selectAllCheckboxUpdate1 = val => {
      selectedNotes.value = val ? notes.value.map(note => note.note_id) : []
    }

    const goToTrashMore = function () {
      let noteIds = selectedNotes.value.join(",")
      store.dispatch('notebook/changeStatus', {
        noteIds: noteIds,
        status: 4,
      }).then(res => {
        if (res.data.code === 0) {
          toast.success('修改成功')
        } else {
          toast.error('修改失败')
        }
      })
    }

    const toggleStarred = email => {
      store.dispatch('app-email/updateEmail', {
        emailIds: [email.id],
        dataToUpdate: {isStarred: !email.isStarred},
      }).then(() => {
        email.isStarred = !email.isStarred
      })
    }

    const moveSelectedEmailsToFolder = folder => {
      store.dispatch('app-email/updateEmail', {
        emailIds: selectedEmails.value,
        dataToUpdate: {folder},
      })
          .then(() => {
            fetchEmails()
          })
          .finally(() => {
            selectedEmails.value = []
          })
    }

    const updateSelectedEmailsLabel = label => {
      store.dispatch('app-email/updateEmailLabels', {
        emailIds: selectedEmails.value,
        label,
      })
          .then(() => {
            fetchEmails()
          })
          .finally(() => {
            selectedEmails.value = []
          })
    }

    const markSelectedEmailsAsUnread = () => {
      store.dispatch('app-email/updateEmail', {
        emailIds: selectedEmails.value,
        dataToUpdate: {isRead: false},
      })
          .then(() => {
            fetchEmails()
          })
          .finally(() => {
            selectedEmails.value = []
          })
    }

    const showEmailDetails = ref(false)
    const emailViewData = ref({})
    const opendedEmailMeta = computed(() => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
      return {
        hasNextEmail: Boolean(emails.value[openedEmailIndex + 1]),
        hasPreviousEmail: Boolean(emails.value[openedEmailIndex - 1]),
      }
    })

    const updateEmailViewData = email => {
      // Mark email is read
      store.dispatch('app-email/updateEmail', {
        emailIds: [email.id],
        dataToUpdate: {isRead: true},
      })
          .then(() => {
            if (!email.isRead && (email.folder === 'inbox' || email.folder === 'spam')) {
              emailsMeta.value[email.folder] -= 1
            }
            email.isRead = true
          })
          .finally(() => {
            emailViewData.value = email
            showEmailDetails.value = true
          })
    }

    const showNoteDetails = ref(false)
    const noteViewData = ref({})
    const opendedNoteMeta = computed(() => {
      const openedNoteIndex = notes.value.findIndex(e => e.note_id === noteViewData.value.note_id)
      return {
        hasNextNote: Boolean(notes.value[openedNoteIndex + 1]),
        hasPreviousNote: Boolean(notes.value[openedNoteIndex - 1]),
      }
    })

    const closeView = function () {
      showNoteDetails.value = false
    }

    const updateNoteViewData = function (note) {
      // Mark email is read
      store.dispatch('notebook/view', {
        id: note.note_id,
      }).then(res => {
        if (res.data.code === 0) {
          noteViewData.value = note
          showNoteDetails.value = true
        } else {
          toast.error('获取详情失败')
        }
      })
    }

    const moveOpenEmailToFolder = folder => {
      selectedEmails.value = [emailViewData.value.id]
      moveSelectedEmailsToFolder(folder)
      selectedEmails.value = []
      showEmailDetails.value = false
    }
    const updateOpenEmailLabel = label => {
      selectedEmails.value = [emailViewData.value.id]
      updateSelectedEmailsLabel(label)

      // Update label in opened email
      const labelIndex = emailViewData.value.labels.indexOf(label)
      if (labelIndex === -1) emailViewData.value.labels.push(label)
      else emailViewData.value.labels.splice(labelIndex, 1)

      selectedEmails.value = []
    }

    const markOpenEmailAsUnread = () => {
      selectedEmails.value = [emailViewData.value.id]
      markSelectedEmailsAsUnread()

      selectedEmails.value = []
      showEmailDetails.value = false
    }

    const changeOpenedEmail = dir => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
      const newEmailIndex = dir === 'previous' ? openedEmailIndex - 1 : openedEmailIndex + 1
      emailViewData.value = emails.value[newEmailIndex]
    }

    const deleteNotes = function () {
      if (confirm("是否彻底删除选中笔记？")) {
        let noteIds = selectedNotes.value.join(",")
        store.dispatch('notebook/deleteNotes', {
          noteIds: noteIds
        }).then(res => {
          if (res.data.code === 0) {
            this.searchList();
            toast.success('删除成功')
          } else {
            toast.error('删除失败')
          }
        })
      }
    }

    watch(routeParams, () => {
      showEmailDetails.value = false
    })

    watch([emailViewData, routeParams], () => {
      selectedEmails.value = []
    })

    // Compose
    const shallShowEmailComposeModal = ref(false)

    const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility()

    const {
      refetchData,

      // UI
    } = notebookUseList()

    return {
      // UI
      perfectScrollbarSettings,

      // Emails & EmailsMeta
      emails,
      emailsMeta,

      notes,
      notesMeta,

      // Mail Selection
      selectAllEmailCheckbox,
      isSelectAllEmailCheckboxIndeterminate,
      selectedEmails,
      selectedNotes,
      toggleSelectedMail,
      selectAllCheckboxUpdate,

      // Note Selection
      selectAllNoteCheckbox,
      isSelectAllNoteCheckboxIndeterminate,
      toggleSelectedNote,
      selectAllCheckboxUpdate1,

      // Mail Actions
      toggleStarred,
      moveSelectedEmailsToFolder,
      updateSelectedEmailsLabel,
      markSelectedEmailsAsUnread,

      // Email Details
      showEmailDetails,
      showNoteDetails,
      emailViewData,
      noteViewData,
      opendedEmailMeta,
      updateEmailViewData,
      opendedNoteMeta,
      updateNoteViewData,
      moveOpenEmailToFolder,
      updateOpenEmailLabel,
      markOpenEmailAsUnread,
      changeOpenedEmail,

      // Search Query
      searchList,
      changeList,
      refetchData,
      searchQuery,
      updateRouteQuery,

      // UI Filters
      filterTags,
      formatDateToMonthShort,
      getCodeLabel,
      goToTrashMore,
      getCodeColor,
      getCodeIcon,
      deleteNotes,
      closeView,
      toDate,
      toTime,
      avatarText,

      // useNote
      resolveLabelColor,

      // Compose
      shallShowEmailComposeModal,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,
    }
  },
  created() {
    this.searchList()
  }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-note.scss";
</style>
