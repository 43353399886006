import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/notebook/search', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchByNoteId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/notebookitem/searchByNoteId', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/notebook/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/notebook/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/notebook/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOneNote(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/notebook/deleteOneNote', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    deleteOneItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/notebookitem/deleteOneItem', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    deleteNotes(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/notebook/deleteNotes', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    saveItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/notebookitem/save', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/notebook/changeStatus', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeOneStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/notebook/changeOneStatus', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/notebook/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
