<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="$emit('update:shall-show-email-compose-modal', true)"
            >
              新增笔记
            </b-button>
          </div>
          <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                  v-for="filter in noteStatusList"
                  :key="filter.value"
                  :to="filter.route"
                  :active="noteStatus===filter.value"
                  @click="changeStatusActive(filter)"
              >
                <feather-icon
                    :icon="filter.icon"
                    size="18"
                    class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.label }}</span>
                <b-badge
                    v-if="emailsMeta[filter.label.toLowerCase()]"
                    pill
                    :variant="`light-${getCodeColor('note_status', filter.value)}`"
                    class="float-right"
                >
                  {{ emailsMeta[filter.label.toLowerCase()] }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>

            <!--             Labels -->
            <h6 class="section-label mt-3 mb-1 px-2">
              优先级——————————
            </h6>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                  v-for="level in noteLevelList"
                  :key="level.value"
                  :to="level.route"
                  :active="noteLevel===level.value"
                  @click="changeLevelActive(level)"
              >
                            <span
                                class="bullet bullet-sm mr-1"
                                :class="`bullet-${level.color}`"
                            />
                <span>{{ level.label }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import {isDynamicRouteActive} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import {getCodeOptions, getCodeColor} from "@core/utils/filter";

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,

    // 3rd Party
    VuePerfectScrollbar,
  },
  data() {
    return {
      noteStatusList: [],
      noteLevelList: [],
      noteType: '',
      noteStatus: '',
      noteLevel: '',
    }
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    emailsMeta: {
      type: Object,
      required: true,
    },
    searchList: {
      type: Function,
      default: null
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const changeStatusActive = function (filter) {
      this.noteStatus = filter.value
      this.searchList({status: filter.value})
    }

    const changeLevelActive = function (filter) {
      this.noteLevel = filter.value
      this.searchList({level: filter.value})
    }

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,

      // Filter & Labels
      getCodeColor,
      changeStatusActive,
      changeLevelActive
    }
  },
  created() {
    let allStatus = {label: '全部', value: '', icon: 'GridIcon', color: 'info'}
    let allLevel = {label: '全部', value: '', color: 'info'}
    let tmpNoteStatusList = getCodeOptions('note_status')
    tmpNoteStatusList.unshift(allStatus)
    this.noteStatusList = tmpNoteStatusList
    let tmpNoteLevelList = getCodeOptions('note_level')
    tmpNoteLevelList.unshift(allLevel)
    this.noteLevelList = tmpNoteLevelList
  }
}
</script>

<style>

</style>
