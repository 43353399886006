<template>
  <b-modal
      id="compose-mail"
      :visible="shallShowEmailComposeModal"
      title="Compose Note"
      modal-class="modal-sticky"
      footer-class="d-flex justify-content-between"
      body-class="p-0"
      size="lg"
      no-fade
      hide-backdrop
      static
      @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <span style="color: #5172e5; font-weight: bolder;">新增 / 编辑</span>
      <div class="modal-actions">
        <feather-icon
            icon="MinusIcon"
            class="cursor-pointer"
            @click="$emit('update:shall-show-email-compose-modal', false)"
        />
        <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="closeNote"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <b-col md="12">
        <b-form-group
            label-cols="1"
            label-cols-lg="1"
            label="附件上传"
            label-for="attachments"
            label-size="sm"
            :class="'mb-1'"
        >
          <attachment-upload :key="noteAtt"
                             :theme="'files'"
                             :attachment_id="'attachments'"
                             :id="noteAtt"
                             :object_type="'note_book'"
                             :object_id="noteBook.note_id"
                             @change="onUploaded"
          />
        </b-form-group>
      </b-col>
      <div>
        <b-dropdown
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            split
            text="发布"
            variant="primary"
            right
            @click="submitNote(2)"
        >
          <b-dropdown-item
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="submitNote(1)">
            暂存
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>

    <!-- Modal: Body -->
    <b-form>

      <div class="compose-mail-form-field">
        <label
            class="form-label"
        >主题: </label>
        <b-form-input
            id="note_title"
            v-model="noteBook.note_title"
        />
      </div>

      <div class="compose-mail-form-field">
        <label
            class="form-label"
        >分享给: </label>
        <v-select
            v-model="userListSelect"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="full_name"
            class="flex-grow-1 email-to-selector"
            :close-on-select="false"
            :options="userList"
            input-id="email-to"
            :dropdown-should-open="openSelect"
        >

          <template #option="{ avatar, full_name }">
            <b-avatar
                size="sm"
                :src="'https://ts4.cn.mm.bing.net/th?id=OIP-C.lUMLYYthJe21Hwg-UsZlpgHaHB&w=256&h=243&c=8&rs=1&qlt=90&o=6&dpr=1.5&pid=3.1&rm=2'"
            />
            <span class="ml-50"> {{ full_name }}</span>
          </template>

          <template #selected-option="{ avatar, full_name }">
            <b-avatar
                size="sm"
                class="border border-white"
                :src="'https://ts4.cn.mm.bing.net/th?id=OIP-C.lUMLYYthJe21Hwg-UsZlpgHaHB&w=256&h=243&c=8&rs=1&qlt=90&o=6&dpr=1.5&pid=3.1&rm=2'"
            />
            <span class="ml-50"> {{ full_name }}</span>
          </template>
        </v-select>
      </div>

      <div class="compose-mail-form-field">
        <label>笔记类型: </label>
        <v-select
            id="note_type"
            :options="noteTypeOptions"
            :clearable="false"
            v-model="noteTypeLabel"
            class="flex-grow-1 email-to-selector"
            @input="changeSelect('note_type',$event)"
        />
      </div>
      <div class="compose-mail-form-field">
        <label>优先级: </label>
        <v-select
            id="note_level"
            :options="noteLevelOptions"
            :clearable="false"
            v-model="noteLevelLabel"
            @input="changeSelect('note_level',$event)"
            class="flex-grow-1 email-to-selector"
        />
      </div>

      <!-- Field: Message - Quill Editor -->
      <div class="message-editor">
        <quill-editor
            id="quil-content"
            v-model="noteBook.content"
            :options="snowOption"
        />
      </div>
    </b-form>

  </b-modal>
</template>

<script>
import {BAvatar, BDropdown, BDropdownDivider, BDropdownItem, BForm, BFormInput,} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {onUnmounted, ref} from '@vue/composition-api'
import {quillEditor} from 'vue-quill-editor'
import vSelect from 'vue-select'
import store from "@/store";
import userStore from "@/views/apps/user/userStore";
import quillConfig from "@/views/apps/news/quill-config";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload';
import notebookStore from "@/views/apps/note/notebookStore";
import {useToast} from "vue-toastification/composition";
import {getCodeLabel, getCodeOptions} from "@core/utils/filter";


export default {
  directives: {
    Ripple,
  },
  components: {
    AttachmentUpload,
    // BSV
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BAvatar,
    BDropdownDivider,

    // 3rd Party
    quillEditor,
    vSelect,
  },
  data() {
    return {
      id: ref(0),
      userList: [],
      userListSelect: [],
      noteLevelOptions: [],
      noteLevelLabel: '',
      noteTypeOptions: [],
      noteTypeLabel: '',
      snowOption: quillConfig,
      noteBook: {},
      noteAtt: '',
    }
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
  },
  inject: ["searchList"],
  setup(_, {emit}) {
    const toast = useToast()

    if (!store.hasModule('user')) store.registerModule('user', userStore)
    if (!store.hasModule('notebook')) store.registerModule('notebook', notebookStore)

    onUnmounted(() => {
      if (store.hasModule('user')) store.unregisterModule('user')
      if (store.hasModule('notebook')) store.unregisterModule('notebook')
    })

    const loadSalesManList = function () {
      store.dispatch('user/search', {
        start: 1,
        order_by: 'user_id',
        order_desc: 'desc',
      })
          .then(response => {
            if (response.data.code === 0) {
              const data = response.data.data
              this.userList = data.ext.list
            } else {
              toast.error("用户获取失败")
            }
          })
    }

    const openSelect = function (VueSelect) {
      return VueSelect.search.length >= 0 && VueSelect.open
    }

    const changeSelect = function (key, event) {
      if (key === 'note_type') {
        this.noteBook.note_type = event.value
        this.noteTypeLabel = getCodeLabel('note_type', event.value)
      } else if (key === 'note_level') {
        this.noteBook.note_level = event.value
        this.noteLevelLabel = getCodeLabel('note_level', event.value)
      }
    }

    const checkValue = function (value) {
      if (value === undefined || value === '' || value === null) {
        return true
      } else {
        return false
      }
    }

    const submitNote = function (status) {
      if (this.checkValue(this.noteBook.note_title)) {
        toast.error("请填写 标题!")
        return false
      }
      if (this.checkValue(this.noteBook.content)) {
        toast.error("请填写 内容!")
        return false
      }
      if (this.checkValue(this.noteBook.note_type)) {
        toast.error("请选择 类型!")
        return false
      }
      if (this.checkValue(this.noteBook.note_level)) {
        toast.error("请选择 优先级!")
        return false
      }

      let userIdArray = [];
      this.userListSelect.forEach(item => {
        userIdArray.push(item.user_id)
      })
      let followUsers = userIdArray.join(",")
      this.noteBook.status = status
      this.noteBook.followUsers = followUsers
      this.noteBook.attachments = this.noteAtt
      store.dispatch('notebook/save', this.noteBook).then(res => {
        if (res.data.code === 0) {
          // 发布成功清空表单
          this.closeNote()
          this.searchList()
          toast.success('发布成功')
        } else {
          toast.error('发布失败')
        }
      })
    }

    const editNote = function () {
      emit('update:shall-show-email-compose-modal', true)
      if (this.id !== 0) {
        store.dispatch('notebook/edit', {
          id: this.id
        }).then(res => {
          if (res.data.code === 0) {
            this.userListSelect = res.data.data.ext.userList
            this.noteBook = res.data.data
            this.noteLevelLabel = getCodeLabel('note_level', res.data.data.note_level)
            this.noteTypeLabel = getCodeLabel('note_type', res.data.data.note_type)
          } else {
            toast.error('笔记信息加载失败')
          }
        })
      }
    }

    const closeNote = function () {
      this.userListSelect = []
      this.noteLevelLabel = ''
      this.noteTypeLabel = ''
      this.noteBook = {}
      this.noteAtt = ''
      emit('update:shall-show-email-compose-modal', false)
    }

    const onUploaded = function (id, attachment, result) {
      this.noteAtt = result
    }

    return {
      closeNote,
      onUploaded,
      editNote,
      submitNote,
      loadSalesManList,
      changeSelect,
      checkValue,

      openSelect,
    }
  },
  created() {
    this.noteLevelOptions = getCodeOptions('note_level');
    this.noteTypeOptions = getCodeOptions('note_type');
    this.loadSalesManList();
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }

    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
