<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
              :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
              size="20"
              class="align-bottom"
              @click="$emit('close-note-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ noteViewData.note_title }}
        </h4>
        <div style="margin-left: 10px">
          <b-form-group>
            <v-select
                v-model="statusSelected"
                :clearable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                @input="changeSelect('note_status',$event)"
                class="select-size-sm"
                :options="statusOptions"
            >
              <template #option="{ label, value }">
                <b-badge pill :variant="`light-${getCodeColor('note_status', value)}`">
                  <feather-icon
                      :icon="`${getCodeIcon('note_status', value)}`"
                      class="mr-25"
                  />
                  {{ label }}
                </b-badge>
              </template>
              <template #selected-option="{ value, label }">
                <b-badge pill :variant="`light-${getCodeColor('note_status', value)}`">
                  <feather-icon
                      :icon="`${getCodeIcon('note_status', value)}`"
                      class="mr-25"
                  />
                  {{ label }}
                </b-badge>
              </template>
            </v-select>
          </b-form-group>
        </div>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">

        <!-- Move to Trash -->
        <feather-icon
            v-if="noteViewData.creator === userData.user_id"
            icon="TrashIcon"
            class="ml-75"
            size="17"
            @click="deleteOneNote"
        />
      </div>
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-scroll-area scroll-area"
    >

      <!-- Action: Show Earlier Message -->
      <b-row
          v-if="!showWholeThread && noteViewData.replies && noteViewData.replies.length"
          class="mb-1"
      >
        <b-col cols="12">
          <b-link
              class="font-weight-bold"
              @click="showWholeThread = true"
          >
            View {{ noteViewData.replies.length }} Earlier Message{{ noteViewData.replies.length > 1 ? 's' : '' }}
          </b-link>
        </b-col>
      </b-row>

      <!-- Earlier Email Messages -->
      <!--      <template v-if="showWholeThread">-->
      <!--        <b-row-->
      <!--            v-for="threadNote in noteViewData.replies.slice().reverse()"-->
      <!--            :key="threadNote.id"-->
      <!--        >-->
      <!--          <b-col cols="12">-->
      <!--            <note-message-card :message="threadNote"/>-->
      <!--          </b-col>-->
      <!--        </b-row>-->
      <!--      </template>-->

      <!-- Email Thread -->
      <b-row>
        <b-col cols="12">
          <note-message-card :message="noteViewData"/>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-card v-if="noteItems.length > 0" class="media-list media-bordered">
            <b-media
                v-for="item in noteItems"
                :key="item.item_id"
                tag="li"
                no-body
                :class="'mail-read'"
            >

              <b-media-aside class="media-left mr-50">
                <b-avatar
                    class="avatar"
                    size="45"
                    variant="light-primary"
                    :src="getCodeIcon('user', item.creator)"
                    :text="avatarText(getCodeLabel('user', item.creator))"
                >
                </b-avatar>
              </b-media-aside>

              <b-media-body>
                <div class="mail-details">
                  <div class="mail-items">
                    <span style="color: rgb(19,155,231);">
                      {{ getCodeLabel('user', item.creator) }}
                    </span>
                    <div
                        class="mail-message"
                        v-html="item.content"
                    />
                    <div class="mail-meta-item" v-if="item.attachments !==''">

                      <div class="d-flex align-items-center mb-1">
                        <feather-icon
                            icon="PaperclipIcon"
                            size="16"
                        />
                        <h5 class="font-weight-bolder text-body mb-0 ml-50">
                          附件
                        </h5>
                      </div>
                      <div class="d-flex flex-column">
                        <attachment-upload :theme="'files'"
                                           :attachment_id="'attachments'"
                                           :id="item.attachments"
                                           :object_type="'note_book_item'"
                                           :object_id="item.item_id"
                                           :readonly="true"
                        />
                      </div>
                    </div>
                    <div align="end">
                      <span>{{ toTime(item.create_time) }}</span>
                      <feather-icon
                          v-if="noteViewData.creator === userData.user_id"
                          icon="TrashIcon"
                          class="ml-75"
                          size="15"
                          @click="deleteOneItem(item.item_id)"
                      />
                    </div>
                  </div>
                </div>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>
      </b-row>

      <!-- Email Actions: Reply or Forward -->
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row align="end">
              <b-col
                  cols="12"
                  class="mt-50"
              >
                <b-button variant="gradient-primary" style="margin-left: 10px;margin-right: 10px" @click="showItem">
                  追加(评论/附件)
                </b-button>
                <b-button
                    style="margin-left: 10px;margin-right: 20px"
                    v-if="noteViewData.creator === userData.user_id"
                    variant="gradient-warning"
                    @click="openEditNote"
                >
                  基本编辑
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
    <b-modal
        ref="openCommon"
        modal-class="modal-sticky"
        footer-class="d-flex justify-content-between"
        body-class="p-0"
        size="lg"
        no-fade
        hide-backdrop
        static
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          追加评论
        </h5>
        <div class="modal-actions">
          <feather-icon
              icon="MinusIcon"
              class="cursor-pointer"
              @click="$emit('update:shall-show-email-compose-modal', false)"
          />
          <feather-icon
              icon="XIcon"
              class="ml-1 cursor-pointer"
              @click="closeItem"
          />
        </div>
      </template>

      <!-- Modal Footer -->
      <template #modal-footer>
        <!-- Footer: Left Content -->
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="附件上传"
              label-for="itemAttachments"
              label-size="sm"
              :class="'mb-1'"
          >
            <attachment-upload :key="itemAttachments"
                               :theme="'files'"
                               :attachment_id="'itemAttachments'"
                               :id="itemAttachments"
                               :object_type="'note'"
                               :object_id="noteItemId"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
        <div>
          <b-button variant="gradient-primary" style="margin-left: 10px;margin-right: 10px" @click="saveNoteItem">
            发布评论
          </b-button>
        </div>
      </template>

      <!-- Modal: Body -->
      <b-form>
        <!-- Field: Message - Quill Editor -->
        <div class="message-editor">
          <quill-editor
              id="quil-content"
              v-model="itemContent"
              :options="snowOption"
          />
        </div>
      </b-form>
    </b-modal>

    <b-modal
        id="compose-mail"
        ref="openCompose"
        modal-class="modal-sticky"
        footer-class="d-flex justify-content-between"
        body-class="p-0"
        size="lg"
        no-fade
        hide-backdrop
        static
    >
      <!-- Modal Header -->
      <template #modal-header>
        <span style="color: #5172e5; font-weight: bolder;">新增 / 编辑</span>
        <div class="modal-actions">
          <feather-icon
              icon="MinusIcon"
              class="cursor-pointer"
              @click="$emit('update:shall-show-email-compose-modal', false)"
          />
          <feather-icon
              icon="XIcon"
              class="ml-1 cursor-pointer"
              @click="closeNote"
          />
        </div>
      </template>

      <!-- Modal Footer -->
      <template #modal-footer>
        <!-- Footer: Left Content -->
        <div>
          <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              split
              text="发布"
              variant="primary"
              right
              @click="submitNote(2)"
          >
            <b-dropdown-item
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="submitNote(1)">
              暂存
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <!-- Modal: Body -->
      <b-form>

        <div class="compose-mail-form-field">
          <label
              class="form-label"
          >主题: </label>
          <b-form-input
              id="note_title"
              v-model="noteBook.note_title"
          />
        </div>

        <div class="compose-mail-form-field">
          <label
              class="form-label"
          >分享给: </label>
          <v-select
              v-model="userListSelect"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="full_name"
              class="flex-grow-1 email-to-selector"
              :close-on-select="false"
              :options="userList"
              input-id="email-to"
              :dropdown-should-open="openSelect"
          >

            <template #option="{ avatar, full_name }">
              <b-avatar
                  size="sm"
                  :src="'https://ts4.cn.mm.bing.net/th?id=OIP-C.lUMLYYthJe21Hwg-UsZlpgHaHB&w=256&h=243&c=8&rs=1&qlt=90&o=6&dpr=1.5&pid=3.1&rm=2'"
              />
              <span class="ml-50"> {{ full_name }}</span>
            </template>

            <template #selected-option="{ avatar, full_name }">
              <b-avatar
                  size="sm"
                  class="border border-white"
                  :src="'https://ts4.cn.mm.bing.net/th?id=OIP-C.lUMLYYthJe21Hwg-UsZlpgHaHB&w=256&h=243&c=8&rs=1&qlt=90&o=6&dpr=1.5&pid=3.1&rm=2'"
              />
              <span class="ml-50"> {{ full_name }}</span>
            </template>
          </v-select>
        </div>

        <div class="compose-mail-form-field">
          <label>笔记类型: </label>
          <v-select
              id="note_type"
              :options="noteTypeOptions"
              :clearable="false"
              v-model="noteTypeLabel"
              class="flex-grow-1 email-to-selector"
              @input="changeSelect('note_type',$event)"
          />
        </div>
        <div class="compose-mail-form-field">
          <label>优先级: </label>
          <v-select
              id="note_level"
              :options="noteLevelOptions"
              :clearable="false"
              v-model="noteLevelLabel"
              @input="changeSelect('note_level',$event)"
              class="flex-grow-1 email-to-selector"
          />
        </div>

        <!-- Field: Message - Quill Editor -->
        <div class="message-editor">
          <quill-editor
              id="quil-content"
              v-model="noteBook.content"
              :options="snowOption"
          />
        </div>
      </b-form>

    </b-modal>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BRow, BCol, BBadge, BCard, BLink, BMedia,
  BMediaAside,
  BMediaBody, BForm, BFormInput, BAvatar, BDropdownDivider,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {onUnmounted, ref, watch} from '@vue/composition-api'
import useNote from './useNote'
import NoteMessageCard from './NoteMessageCard.vue'
import {getCodeLabel, getCodeOptions, toDate, toTime, getCodeIcon, avatarText, getCodeColor} from '@core/utils/filter'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload';
import {getUserData} from "@/auth/utils";
import quillConfig from "@/views/apps/news/quill-config";
import {quillEditor} from "vue-quill-editor";
import {useToast} from "vue-toastification/composition";
import store from "@/store";
import notebookStore from "@/views/apps/note/notebookStore";
import userStore from "@/views/apps/user/userStore";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormInput,
    BAvatar,
    BDropdownDivider,

    // 3rd Party
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,
    quillEditor,
    AttachmentUpload,
    BMedia,
    BMediaAside,
    BMediaBody,

    VuePerfectScrollbar,
    vSelect,

    NoteMessageCard,
  },
  props: {
    noteViewData: {
      type: Object,
      required: true,
    },
    opendedNoteMeta: {
      type: Object,
      required: true,
    },
    searchList: {
      type: Function,
      default: null
    },
  },
  inject: ["closeView", "searchList", "updateNoteViewData"],
  data() {
    return {
      noteItemId: 0,
      noteItem: {},
      noteItems: [],
      itemAttachments: '',
      itemContent: '',
      snowOption: quillConfig,
      showItemModal: false,
      statusOptions: [],
      status: 0,
      statusSelected: '',
      userList: [],
      userListSelect: [],
      noteLevelOptions: [],
      noteLevelLabel: '',
      noteTypeOptions: [],
      noteTypeLabel: '',
      noteBook: {},
      noteAttachments: ''
    }
  },
  setup(props, emit) {
    const toast = useToast()
    if (!store.hasModule('user')) store.registerModule('user', userStore)
    if (!store.hasModule('notebook')) store.registerModule('notebook', notebookStore)

    onUnmounted(() => {
      if (store.hasModule('user')) store.unregisterModule('user')
      if (store.hasModule('notebook')) store.unregisterModule('notebook')
    })

    const loadSalesManList = function () {
      store.dispatch('user/search', {
        start: 1,
        order_by: 'user_id',
        order_desc: 'desc',
      })
          .then(response => {
            if (response.data.code === 0) {
              const data = response.data.data
              this.userList = data.ext.list
            } else {
              toast.error("用户获取失败")
            }
          })
    }

    const openSelect = function (VueSelect) {
      return VueSelect.search.length >= 0 && VueSelect.open
    }

    const submitNote = function (status) {
      if (this.checkValue(this.noteBook.note_title)) {
        toast.error("请填写 标题!")
        return false
      }
      if (this.checkValue(this.noteBook.content)) {
        toast.error("请填写 内容!")
        return false
      }
      if (this.checkValue(this.noteBook.note_type)) {
        toast.error("请选择 类型!")
        return false
      }
      if (this.checkValue(this.noteBook.note_level)) {
        toast.error("请选择 优先级!")
        return false
      }

      let userIdArray = [];
      this.userListSelect.forEach(item => {
        userIdArray.push(item.user_id)
      })
      let followUsers = userIdArray.join(",")
      this.noteBook.note_id = this.noteViewData.note_id
      this.noteBook.status = status
      this.noteBook.followUsers = followUsers
      store.dispatch('notebook/save', this.noteBook).then(res => {
        if (res.data.code === 0) {
          // 发布成功清空表单
          this.closeNote()
          this.closeView()
          this.searchList()
          toast.success('发布成功')
        } else {
          toast.error('发布失败')
        }
      })
    }

    const closeNote = function () {
      this.userListSelect = []
      this.noteLevelLabel = ''
      this.noteTypeLabel = ''
      this.noteBook = {}
      this.$refs['openCompose'].hide()
    }
    const {resolveLabelColor} = useNote()

    const userData = getUserData()

    const shallShowEmailComposeModal = ref(false)

    const openEditNote = function () {
      this.$refs['openCompose'].show()
      store.dispatch('notebook/edit', {
        id: this.noteViewData.note_id
      }).then(res => {
        if (res.data.code === 0) {
          this.userListSelect = res.data.data.ext.userList
          this.noteBook = res.data.data
          this.noteLevelLabel = getCodeLabel('note_level', res.data.data.note_level)
          this.noteTypeLabel = getCodeLabel('note_type', res.data.data.note_type)
        } else {
          toast.error('笔记信息加载失败')
        }
      })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }
    const showItem = function () {
      this.$refs['openCommon'].show()
    }

    const closeItem = function () {
      this.itemContent = ''
      this.itemAttachments = ''
      this.$refs['openCommon'].hide()
    }

    const changeSelect = function (key, event) {
      if (key === 'note_status') {
        this.status = event.value
        this.statusSelected = event
        this.changeOneStatus()
      } else if (key === 'note_type') {
        this.noteBook.note_type = event.value
        this.noteTypeLabel = getCodeLabel('note_type', event.value)
      } else if (key === 'note_level') {
        this.noteBook.note_level = event.value
        this.noteLevelLabel = getCodeLabel('note_level', event.value)
      }
    }

    const saveNoteItem = function () {
      this.noteItem.note_id = this.noteViewData.note_id
      this.noteItem.content = this.itemContent
      this.noteItem.attachments = this.itemAttachments
      store.dispatch('notebook/saveItem', this.noteItem).then(res => {
        if (res.data.code === 0) {
          this.searchByNoteId()
          this.closeItem()
          toast.success('评论成功')
        } else {
          toast.error('评论失败')
        }
      })
    }

    const searchByNoteId = function () {
      store.dispatch('notebook/searchByNoteId', {
        noteId: this.noteViewData.note_id
      }).then(res => {
        if (res.data.code === 0) {
          this.noteItems = res.data.data
        } else {
          toast.error('评论列表加载失败')
        }
      })
    }

    const deleteOneNote = function () {
      if (this.status !== 4) {
        if (confirm("是否作废？")) {
          store.dispatch('notebook/changeOneStatus', {
            noteId: this.noteViewData.note_id,
            status: 4
          }).then(res => {
            if (res.data.code === 0) {
              this.status = res.data.data.status
              toast.success('已作废')
              this.closeView()
              this.searchList()
            } else {
              toast.error('作废失败')
            }
          })
        }
      } else {
        if (confirm("是否彻底删除？")) {
          store.dispatch('notebook/deleteOneNote', {
            noteId: this.noteViewData.note_id,
          }).then(res => {
            if (res.data.code === 0) {
              this.closeView()
              this.searchList()
              toast.success('删除成功')
            } else {
              toast.error('删除失败')
            }
          })
        }
      }
    }

    const deleteOneItem = function (itemId) {
      if (confirm("是否删除？")) {
        store.dispatch('notebook/deleteOneItem', {
          itemId: itemId,
        }).then(res => {
          if (res.data.code === 0) {
            this.searchByNoteId()
          } else {
            toast.error('删除失败')
          }
        })
      }
    }

    const changeOneStatus = function () {
      store.dispatch('notebook/changeOneStatus', {
        noteId: this.noteViewData.note_id,
        status: this.status
      }).then(res => {
        if (res.data.code === 0) {
          this.status = res.data.data.status
          toast.success('修改成功')
          this.searchList()
        } else {
          toast.error('修改失败')
        }
      })
    }

    const showWholeThread = ref(false)

    const onUploaded = function (id, attachment, result) {
      this.itemAttachments = result
    }

    const checkValue = function (value) {
      if (value === undefined || value === '' || value === null) {
        return true
      } else {
        return false
      }
    }

    watch(() => props.noteViewData.note_id, () => {
      showWholeThread.value = false
    })

    return {
      userData,
      toDate,
      toTime,
      getCodeIcon,
      avatarText,
      getCodeColor,
      openEditNote,
      saveNoteItem,
      onUploaded,
      showItem,
      closeItem,
      searchByNoteId,
      getCodeLabel,
      deleteOneNote,
      checkValue,
      changeSelect,
      changeOneStatus,
      closeNote,
      submitNote,
      loadSalesManList,
      deleteOneItem,

      openSelect,

      // UI
      perfectScrollbarSettings,
      showWholeThread,

      // useNote
      resolveLabelColor,
      shallShowEmailComposeModal,
    }
  },
  watch: {
    noteViewData: function () {
      this.status = this.noteViewData.status
      for (let i = 0; i < this.statusOptions.length; i++) {
        if (this.statusOptions[i].value == this.noteViewData.status) {
          this.statusSelected = this.statusOptions[i]
        }
      }
      this.searchByNoteId()
    }
  },
  created() {
    this.statusOptions = getCodeOptions('note_status')
    this.noteLevelOptions = getCodeOptions('note_level');
    this.noteTypeOptions = getCodeOptions('note_type');
    this.loadSalesManList();
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }

    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
